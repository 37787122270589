import React from "react";
import PropTypes from "prop-types";
import defaultLogo from "./assets/page-logo.svg";
import "./Slogan.less";

const SloganComponent = ({ obj, isShowPc }) => {
  const { data } = obj;
  const {
    logo,
    desc,
    ga,
    icp,
    copyrightInformation,
    descColor,
    textColor,
    backgroundColor,
  } = data;
  return (
    <section className={"slogan"} style={{ backgroundColor }}>
      {logo && <img src={logo} alt="品牌logo" />}
      {desc && (
        <p className={"desc"} style={{ color: descColor }}>
          {desc}
        </p>
      )}
      {copyrightInformation && (
        <p className={"copyright"} style={{ color: textColor }}>
          {copyrightInformation}
        </p>
      )}
      {(icp?.text || ga?.text) && (
        <p className={"link"}>
          {icp?.text && (
            <a
              href={icp?.link || "javascript:;"}
              target={icp?.link ? "_blank" : ""}
              style={{
                color: textColor,
              }}
            >
              {icp.text}
            </a>
          )}
          {ga?.text && (
            <a
              href={ga?.link || "javascript:;"}
              target={ga?.link ? "_blank" : ""}
              style={{
                color: textColor,
              }}
            >
              {ga.text}
            </a>
          )}
        </p>
      )}
    </section>
  );
};

SloganComponent.propTypes = {
  obj: PropTypes.object.isRequired,
  isShowPc: PropTypes.bool,
};

export default React.memo(SloganComponent);
