import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import "moment/locale/zh-cn";
import arrow from "./assets/icon_arrow_top.png";
import playingIcon from "./assets/playing.gif";
import forwardIcon from "./assets/icon_forward_default.png";
import backIcon from "./assets/icon_back_default.png";
import forwardDisabledIcon from "./assets/icon_forward_disable.png";
import backDisabledIcon from "./assets/icon_back_disable.png";
import "./Activity.less";

const host =
  location.origin.indexOf("localhost") > -1
    ? "//shangzhibo.tv"
    : location.origin;

const Activity = ({ obj, isShowPc, showMoreActivity, index, id, seeMore }) => {
  const [tab, setTab] = useState(0);
  const [showList, setShowList] = useState([]);
  const { data, type } = obj;
  const {
    menuArr,
    showType,
    showUV = "on",
    showPrice = "on",
    showTag = "content",
    showShare = "off",
  } = data;
  const elCount = menuArr[tab]?.num > 4 ? Math.ceil(menuArr[tab]?.num / 2) : 0;
  const twoLineWidth = elCount > 2 ? (elCount - 2) * 168 : 0;
  const scrollTab = useRef(null);
  const isAndroid = window.navigator.userAgent.indexOf("Android") > -1;

  useEffect(() => {
    if (type === "card") return;
    const getActivities = (arr) => {
      if (arr.length > 0) {
        axios
          .get(
            `${host}/api/page/${id}/activity/list?activityIds=${arr.join(",")}`
          )
          .then((res) => {
            setShowList(res.data);
          });
      } else {
        setShowList([]);
      }
    };

    const getActivitiesByGroup = (groupId, page = 1, pageSize = 10) => {
      if (groupId && groupId !== "") {
        axios
          .get(
            type === "activity-tab"
              ? `${host}/api/v3/activity-group/${groupId}/activities?page=${page}&pageSize=${pageSize}`
              : `${host}/api/v3/activity-group/${groupId}/activities?page=${page}&pageSize=${pageSize}&type=${
                  type === "video" ? "video" : "live"
                }`
          )
          .then((res) => {
            setShowList(res.data.items);
          });
      }
      setShowList([]);
    };

    if (menuArr[tab].type === "group") {
      getActivitiesByGroup(menuArr[tab].groupId, 1);
    } else {
      getActivities(menuArr[tab].activities);
    }
  }, [tab]);

  const timeCheck = (startedAt, endedAt) => {
    const now = Date.now();
    const notStart = new Date(startedAt) > now;
    const finished = new Date(endedAt) < now;

    if (notStart) {
      return "notStart";
    }
    if (finished) {
      return "finished";
    }
    return "onGoing";
  };

  const getPv = (pv) => (pv >= 100000 ? `${parseInt(pv / 10000, 10)}w` : pv);

  const getStyle = (type, st) => ({
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    height: "auto",
    maxHeight: st === "horizontal" ? 80 : 40,
    fontFamily: "PingFangSC-Medium, PingFang SC",
    whiteSpace: "unset",
    WebkitLineClamp: st === "horizontal" ? 4 : 2,
    margin: type === "card" ? "8px 0 0" : "",
  });

  const getList = (st, arr, n) =>
    (arr || []).map((item, i) => {
      if (i < n && item) {
        const t = item.template;
        let now = new Date().getTime();
        const isBegin = now > new Date(item.startedAt).getTime();
        const isOver = now > new Date(item.endedAt).getTime();
        const isLiving = item.isPushing && isBegin && !isOver; // 直播中
        return (
          <li
            className={`activities ${st} ${type === "card" ? "card" : ""}`}
            key={i}
            style={{ boxShadow: type === "card" && isShowPc ? "none" : "" }}
          >
            <a target="_blank" href={item.link || item.hyperlink}>
              <div
                className="cover"
                style={{
                  backgroundImage: `url('${t ? t.imgCover : item.image}')`,
                }}
              >
                <div
                  className="modal"
                  style={{
                    opacity:
                      isLiving && ["vertical", "horizontal"].includes(st)
                        ? ""
                        : 0,
                  }}
                >
                  <div className="special">
                    <img src={playingIcon} alt="" />
                    <div>正在直播</div>
                  </div>
                </div>
              </div>
              <div
                className="desc"
                style={{
                  height: type === "card" && st === "halfLine" ? "auto" : "",
                  padding: type === "card" && st !== "vertical" ? 0 : "",
                }}
              >
                {["halfLine", "oneLine", "twoLine"].includes(st) &&
                type !== "card" ? (
                  <span
                    className="uv"
                    style={{
                      display:
                        showUV === "off" ||
                        timeCheck(item.startedAt, item.endedAt) === "notStart"
                          ? "none"
                          : "inline-block",
                    }}
                  >
                    {getPv(item.pv || 0)}人在看
                  </span>
                ) : null}
                <div className="left">
                  <p
                    className="tt"
                    style={type === "card" ? getStyle(type, st) : {}}
                  >
                    {type === "card" ? item.text : item.name}
                  </p>
                  {showType === "vertical" &&
                    showShare === "on" &&
                    item?.template?.share?.wechat?.text && (
                      <p
                        className="share"
                        style={{ marginBottom: 8, color: "#999", fontSize: 12 }}
                      >
                        {item?.template?.share?.wechat?.text}
                      </p>
                    )}
                  {type !== "card" && t ? (
                    <div className="ct">
                      <div>
                        <div
                          className="icon"
                          style={{
                            background:
                              showTag === "content"
                                ? item?.type === "video"
                                  ? "#f0f3f7"
                                  : timeCheck(item.startedAt, item.endedAt) ===
                                    "notStart"
                                  ? "#def6e1"
                                  : timeCheck(item.startedAt, item.endedAt) ===
                                    "onGoing"
                                  ? "#dfedff"
                                  : "#fff4ea"
                                : `${item?.group?.backgroundColor}`,
                            color:
                              showTag === "content"
                                ? item?.type === "video"
                                  ? "#888"
                                  : timeCheck(item.startedAt, item.endedAt) ===
                                    "notStart"
                                  ? "#2cb34f"
                                  : timeCheck(item.startedAt, item.endedAt) ===
                                    "onGoing"
                                  ? "#2282ff"
                                  : "#f57e09"
                                : `${item?.group?.textColor}`,
                          }}
                        >
                          {showTag === "content"
                            ? item?.type === "video"
                              ? "视频"
                              : timeCheck(item.startedAt, item.endedAt) ===
                                "notStart"
                              ? "预约"
                              : timeCheck(item.startedAt, item.endedAt) ===
                                "onGoing"
                              ? "直播"
                              : "回看"
                            : `${
                                item?.group?.name
                                  ? item?.group?.name.substring(0, 2)
                                  : ""
                              }`}
                        </div>
                        <div
                          className="time"
                          style={{
                            color:
                              timeCheck(item.startedAt, item.endedAt) ===
                              "onGoing"
                                ? "#2282ff"
                                : "#999",
                            visibility:
                              item?.type === "video" ? "hidden" : "unset",
                          }}
                        >
                          {["onGoing", "notStart"].includes(
                            timeCheck(item.startedAt, item.endedAt)
                          )
                            ? moment(item.startedAt).calendar(null, {
                                sameElse: "YYYY年M月DD日",
                                nextWeek: "YYYY年M月DD日",
                                lastWeek: "YYYY年M月DD日",
                              })
                            : `${moment(item.startedAt).format(
                                isAndroid ? "M月DD日" : "YYYY年M月DD日"
                              )}`}
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{
                          justifyContent:
                            st === "vertical" &&
                            t &&
                            t.auth &&
                            t.auth.methods
                              .map((auth) => auth[0])
                              .indexOf("pay") > -1
                              ? ""
                              : "flex-start",
                        }}
                      >
                        <div
                          className="price"
                          style={{
                            marginRight:
                              ["horizontal"].includes(st) &&
                              !isShowPc &&
                              t &&
                              t.auth &&
                              t.auth.methods
                                .map((auth) => auth[0])
                                .indexOf("pay") > -1
                                ? 8
                                : 0,
                            display: showPrice === "on" ? "" : "none",
                          }}
                        >
                          {t &&
                          t.auth &&
                          t.auth.methods.map((auth) => auth[0]).indexOf("pay") >
                            -1
                            ? `￥${t.auth.payPrice}`
                            : ""}
                        </div>
                        {["vertical", "horizontal"].includes(st) ? (
                          <span
                            className="uv"
                            style={{
                              display:
                                showUV === "off" ||
                                timeCheck(item.startedAt, item.endedAt) ===
                                  "notStart"
                                  ? "none"
                                  : "inline-block",
                            }}
                          >
                            {getPv(item.pv || 0)}人在看
                          </span>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </a>
          </li>
        );
      }
      return null;
    });

  const activityList = (
    <div className="activityList">
      <h4>
        <span>{menuArr[tab].title}</span>
        <span
          style={{
            display:
              menuArr[tab].type === "group" && showList.length > 0
                ? ""
                : menuArr[tab].num < showList.length
                ? ""
                : "none",
            fontSize: isShowPc ? 14 : "",
          }}
          onClick={() => {
            seeMore(index);
          }}
        >
          查看更多
          <img src={arrow} alt="" />
        </span>
      </h4>
      <div
        style={{
          width: ["twoLine"].includes(showType)
            ? isShowPc
              ? "100%"
              : "calc(100% + 24px)"
            : "unset",
          overflowX: ["twoLine"].includes(showType) ? "scroll" : "unset",
        }}
      >
        <ul
          className="lists"
          style={{
            overflowX: ["oneLine", "twoLine"].includes(showType)
              ? "scroll"
              : "none",
            flexWrap: !isShowPc
              ? ["oneLine"].includes(showType)
                ? "nowrap"
                : "wrap"
              : null,
            width: !isShowPc
              ? ["twoLine"].includes(showType)
                ? `calc(100% + ${twoLineWidth}px`
                : ["oneLine"].includes(showType)
                ? "calc(100% + 24px)"
                : "100%"
              : "",
            maxHeight: !isShowPc
              ? ["twoLine"].includes(showType)
                ? 411
                : "unset"
              : "",
          }}
        >
          {getList(
            isShowPc && ["oneLine", "twoLine"].includes(showType)
              ? "vertical"
              : showType,
            showList,
            menuArr[tab].num
          )}
        </ul>
      </div>
    </div>
  );

  const tabList = (
    <div className="tabList">
      <div className="tabList_box">
        <ul
          className="tabList_tab"
          ref={scrollTab}
          style={{
            justifyContent:
              menuArr.length > 1 && menuArr.length < 5 ? "space-around" : "",
            maxWidth: isShowPc && menuArr.length < 5 ? "unset" : 750,
          }}
        >
          {menuArr.map((item, i) => (
            <li
              key={i}
              className={i === tab && menuArr.length > 1 ? `active` : ""}
              onClick={() => {
                setTab(i);
              }}
              style={{
                maxWidth: menuArr.length === 1 ? "unset" : "",
                marginRight: menuArr.length > 4 ? 24 : "unset",
              }}
            >
              {item.title}
            </li>
          ))}
        </ul>
        {isShowPc && menuArr.length > 4 ? (
          <div>
            <img
              src={tab === 0 ? backDisabledIcon : backIcon}
              alt=""
              onClick={() => {
                setTab(tab === 0 ? 0 : tab - 1);
                if (tab < menuArr.length - 4) {
                  scrollTab.current.scrollLeft -= 148;
                }
              }}
            />
            <img
              src={
                tab === menuArr.length - 1 ? forwardDisabledIcon : forwardIcon
              }
              alt=""
              onClick={() => {
                if (tab > 3) {
                  scrollTab.current.scrollLeft += 148;
                }
                setTab(
                  tab === menuArr.length - 1 ? menuArr.length - 1 : tab + 1
                );
              }}
            />
          </div>
        ) : null}
      </div>
      <div>
        <ul
          className="lists"
          style={{
            display: ["halfLine"].includes(showType) ? "flex" : "",
            flexWrap: "wrap",
          }}
        >
          {getList(
            isShowPc && ["oneLine", "twoLine"].includes(showType)
              ? "vertical"
              : showType,
            type === "card" ? menuArr[tab].items : showList,
            menuArr[tab].num
          )}
        </ul>
        <p
          className="seeMore"
          style={{
            display:
              type === "card"
                ? menuArr[tab].num < menuArr[tab].items.length
                  ? ""
                  : "none"
                : menuArr[tab].type === "group" && showList.length > 0
                ? ""
                : menuArr[tab].num < showList.length
                ? ""
                : "none",
          }}
        >
          <span
            onClick={() => {
              seeMore(index);
            }}
          >
            查看更多
            <img src={arrow} alt="" />
          </span>
        </p>
      </div>
    </div>
  );

  return (
    <div className="activitySection">
      {["activity", "video"].includes(type) ? activityList : tabList}
    </div>
  );
};

Activity.propTyoes = {
  obj: PropTypes.object.isRequired,
  isShowPc: PropTypes.bool.isRequired,
  showMoreActivity: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  seeMore: PropTypes.func.isRequired,
};

export default React.memo(Activity);
