import React from "react";
import PropTypes from "prop-types";
import searchIcon from "./assets/icon_search.png";
import "./Search.less";

const index = ({ onSearch }) => {
  return (
    <div className="search">
      <div>
        <img src={searchIcon} alt="" />
        <input
          placeholder="请输入"
          onFocus={() => {
            onSearch();
          }}
        />
      </div>
    </div>
  );
};

index.propTypes = {};

export default index;
