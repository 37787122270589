import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import arrow from "./assets/icon_arrow_top.png";
import "./App.less";

const host =
  location.origin.indexOf("localhost") > -1
    ? "//shangzhibo.tv"
    : location.origin;

const CatalogComponent = ({ obj, index, seeMore, id }) => {
  const { data } = obj;
  const [showList, setShowList] = useState([]);
  const [tab, setTab] = useState(0);
  const [activityList, setActivityList] = useState([]);
  const { catalogs, showType, showNum, num, title, showShare } = data;
  const isAndroid = window.navigator.userAgent.indexOf("Android") > -1;

  const calcTotal = (item) => {
    const { chapters = [] } = item;
    let num = 0;
    chapters.forEach((itm) => {
      num += itm?.activityIds?.length;
    });
    return num;
  };
  const getCatalogs = (arr) => {
    if (arr.length > 0) {
      axios
        .get(`${host}/api/page/${id}/catalog/list?catalogIds=${arr.join(",")}`)
        .then((res) => {
          setShowList(res.data);
        });
    }
  };

  const getActivities = (arr) => {
    if (arr.length > 0) {
      axios
        .get(
          `${host}/api/page/${id}/activity/list?activityIds=${arr.join(",")}`
        )
        .then((res) => {
          setActivityList(res.data);
        });
    } else {
      setActivityList([]);
    }
  };

  useEffect(() => {
    getCatalogs(catalogs);
  }, [data]);

  useEffect(() => {
    if (showList?.length === 1 && showType === "customize") {
      getActivities(showList[0]?.chapters?.[tab].activityIds);
    }
  }, [tab, showList, showType]);

  const timeCheck = (startedAt, endedAt) => {
    const now = Date.now();
    const notStart = new Date(startedAt) > now;
    const finished = new Date(endedAt) < now;

    if (notStart) {
      return "notStart";
    }
    if (finished) {
      return "finished";
    }
    return "onGoing";
  };

  const getPv = (pv) => (pv >= 100000 ? `${parseInt(pv / 10000, 10)}w` : pv);

  const renderList = (arr) => {
    return arr.map((item, i) => {
      if (i < num) {
        return (
          <li
            className={`activities ${
              showType === "customize" ? "horizontal" : showType
            }`}
            key={item.id}
          >
            <a href={item?.link} target="_blank">
              <div
                className="cover"
                style={{
                  backgroundImage: `url('${
                    showType === "customize"
                      ? item?.template?.imgCover
                      : item?.appearance?.cover
                  }')`,
                }}
              />
              <div className="desc">
                <div className="left">
                  <p className="tt">{item.name}</p>
                  {showType === "vertical" &&
                    showShare === "on" &&
                    item?.share?.wechat?.text && (
                      <p
                        className="share"
                        style={{
                          marginBottom: 8,
                          color: "#999",
                          fontSize: 12,
                        }}
                      >
                        {item?.share?.wechat?.text}
                      </p>
                    )}
                  <div className="ct">
                    <div>
                      <div
                        className="icon"
                        style={{
                          background:
                            showType === "customize"
                              ? item?.type === "video"
                                ? "#f0f3f7"
                                : timeCheck(item.startedAt, item.endedAt) ===
                                  "notStart"
                                ? "#def6e1"
                                : timeCheck(item.startedAt, item.endedAt) ===
                                  "onGoing"
                                ? "#dfedff"
                                : "#fff4ea"
                              : "#F6E9FF",
                          color:
                            showType === "customize"
                              ? item?.type === "video"
                                ? "#888"
                                : timeCheck(item.startedAt, item.endedAt) ===
                                  "notStart"
                                ? "#2cb34f"
                                : timeCheck(item.startedAt, item.endedAt) ===
                                  "onGoing"
                                ? "#2282ff"
                                : "#f57e09"
                              : "#6915a4",
                        }}
                      >
                        {showType === "customize"
                          ? item?.type === "video"
                            ? "视频"
                            : timeCheck(item.startedAt, item.endedAt) ===
                              "notStart"
                            ? "预约"
                            : timeCheck(item.startedAt, item.endedAt) ===
                              "onGoing"
                            ? "直播"
                            : "回看"
                          : "合辑"}
                      </div>
                      <div
                        className="time"
                        style={{
                          display:
                            showNum === "on" || showType === "customize"
                              ? ""
                              : "none",
                          color: ["horizontal", "customize"].includes(showType)
                            ? "#999"
                            : "",
                        }}
                      >
                        {showType === "customize"
                          ? ["onGoing", "notStart"].includes(
                              timeCheck(item.startedAt, item.endedAt)
                            )
                            ? moment(item.startedAt).calendar(null, {
                                sameElse: "YYYY年M月DD日",
                                nextWeek: "YYYY年M月DD日",
                                lastWeek: "YYYY年M月DD日",
                              })
                            : `${moment(item.startedAt).format(
                                isAndroid ? "M月DD日" : "YYYY年M月DD日"
                              )}`
                          : `已更新${calcTotal(item)}个活动`}
                      </div>
                    </div>
                    <div className="row">
                      {showType === "customize" && (
                        <span
                          className="uv"
                          style={{
                            display:
                              timeCheck(item.startedAt, item.endedAt) ===
                              "notStart"
                                ? "none"
                                : "inline-block",
                          }}
                        >
                          {getPv(item.pv || 0)}人在看
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        );
      }
      return "";
    });
  };

  return (
    <div className="activitySection">
      <div className="activityList">
        <h4>
          <span>{title}</span>
          <span
            style={{
              display:
                num <
                (showType === "customize"
                  ? showList[0]?.chapters?.length
                  : catalogs?.length)
                  ? ""
                  : "none",
            }}
            onClick={() => {
              seeMore(index);
            }}
          >
            查看更多
            <img src={arrow} alt="" />
          </span>
        </h4>
        {catalogs?.length === 1 &&
          showList[0]?.chapters?.length > 0 &&
          showType === "customize" && (
            <ul className="catalogTab">
              {showList[0].chapters.map((item, i) => (
                <li
                  className={`${tab === i ? "active" : ""}`}
                  key={i}
                  onClick={() => setTab(i)}
                >
                  {item.selection}
                </li>
              ))}
            </ul>
          )}
        <ul
          className="lists"
          style={{
            overflowX: ["oneLine", "twoLine"].includes(showType)
              ? "scroll"
              : "none",
            flexWrap: ["oneLine"].includes(showType) ? "nowrap" : "wrap",
          }}
        >
          {renderList(showType === "customize" ? activityList : showList)}
        </ul>
      </div>
    </div>
  );
};

CatalogComponent.propTypes = {
  componentChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  delComponent: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default CatalogComponent;
