import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import icon from "./assets/img_empty_countdown@2x.png";
import "./Countdown.less";

const Countdown = ({ obj, isShowPc }) => {
  const { data } = obj;
  const { title, subtitle, time } = data;
  const { startedAt, endedAt } = time;
  const [duration, setDuration] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (endedAt) {
      if (timer) {
        clearInterval(timer);
        setTimer(null);
      }
      const t = setInterval(() => {
        setDuration(dayjs.duration(dayjs(endedAt).diff(dayjs())));
      }, 1000);
      setTimer(t);
    }
  }, [endedAt]);

  useEffect(
    () => () => {
      clearInterval(timer);
      setTimer(null);
      setDuration(null);
    },
    []
  );

  return (
    <div
      className="countdown"
      style={{
        display:
          !startedAt ||
          (startedAt &&
            endedAt &&
            dayjs(startedAt)?.isBefore(dayjs()) &&
            dayjs(endedAt)?.isAfter(dayjs()))
            ? "flex"
            : "none",
      }}
    >
      {!!title?.text?.length && (
        <h3 className="title" style={{ color: title?.color }}>
          {title?.text}
        </h3>
      )}
      {!!subtitle?.text?.length && (
        <p
          className="subtitle"
          style={{
            color: subtitle?.color,
            marginBottom: endedAt ? (isShowPc ? 24 : 20) : 16,
          }}
        >
          {subtitle?.text}
        </p>
      )}
      <div
        className="countdown-content"
        style={{ flexDirection: endedAt ? "row" : "column" }}
      >
        {endedAt ? (
          <>
            <span
              className="time"
              style={{
                color: time?.fontColor,
                background: time?.backgroundColor,
              }}
            >
              {duration?.days() || 0}
            </span>
            <span className="unit">天</span>
            <span
              className="time"
              style={{
                color: time?.fontColor,
                background: time?.backgroundColor,
              }}
            >
              {duration?.hours() || 0}
            </span>
            <span className="unit">时</span>
            <span
              className="time"
              style={{
                color: time?.fontColor,
                background: time?.backgroundColor,
              }}
            >
              {duration?.minutes() || 0}
            </span>
            <span className="unit">分</span>
            <span
              className="time"
              style={{
                color: time?.fontColor,
                background: time?.backgroundColor,
              }}
            >
              {duration?.seconds() || 0}
            </span>
            <span className="unit">秒</span>
          </>
        ) : (
          <>
            <img src={icon} alt="" />
            <p>请设置倒计时时间</p>
          </>
        )}
      </div>
    </div>
  );
};

export default Countdown;
