import React, { useRef } from "react";
import PropTypes from "prop-types";
import "./Player.less";

const Player = ({ obj, isShowPc }) => {
  const { data } = obj;
  const { title, source, poster } = data;
  const outerElement = document.getElementById("root");
  const containerWidth = Number(
    window.getComputedStyle(outerElement)?.width.replace("px", "") - 48
  );
  const playerRef = useRef(null);

  return (
    <div className="player">
      {title && <p className="title">{title}</p>}
      <video
        ref={playerRef}
        crossorigin
        controls
        poster={poster}
        width={isShowPc ? 900 : containerWidth}
        height={isShowPc ? 506.25 : containerWidth * 0.5625}
        controlsList="nodownload"
      >
        <source src={source} type="video/mp4" />
        <a href={source}>MP4</a>
      </video>
    </div>
  );
};

Player.propTypes = {};

export default Player;
