import React, { useState } from "react";
import PropTypes from "prop-types";
import { Anchor } from "antd";
import "./App.less";

const Elevator = ({ obj, isShowPc, agentId, componentArr }) => {
  const [tab, setTab] = useState(0);
  const { data, type } = obj;
  const { menuArr } = data;
  const topHeader = componentArr?.[0]?.type === "header";

  return (
    <div className={`elevatorSection`}>
      <Anchor
        direction="horizontal"
        offsetTop={topHeader ? (isShowPc ? 72 : 40) : 0}
        targetOffset={topHeader ? (isShowPc ? 137 : 100) : 65}
        onChange={(e) => {
          const id = e.split("#")[1];
          const currentMenu = menuArr?.findIndex(
            (item) => item?.partNumber === id
          );
          setTab(currentMenu > 0 ? currentMenu : 0);
        }}
        getContainer={() => document.querySelector(".content")}
      >
        {menuArr.map((item, i) => (
          <Anchor.Link
            key={i}
            className={
              i === tab && menuArr.length > 1
                ? [129293, 132546, 127428, 132565].includes(agentId)
                  ? "special_active"
                  : `active`
                : ""
            }
            style={{
              maxWidth: menuArr.length === 1 ? "unset" : "",
              marginRight: menuArr.length > 4 ? 24 : "unset",
            }}
            href={item.partNumber ? `#${item.partNumber}` : `JavaScript:;`}
            title={item.name}
          />
        ))}
      </Anchor>
    </div>
  );
};

Elevator.propTypes = {
  obj: PropTypes.object.isRequired,
  isShowPc: PropTypes.bool.isRequired,
};

export default React.memo(Elevator);
