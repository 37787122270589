const ua = navigator.userAgent,
  isWindowsPhone = /(?:Windows Phone)/.test(ua),
  isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
  isFireFox = /(?:Firefox)/.test(ua),
/*   isChrome = /(?:Chrome)/.test(ua), */
/*   isSafari = /(?:Safari)/.test(ua) && !isChrome, */
  isAndroid = /(?:Android)/.test(ua),
  isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
/*   isIPad = /(?:iPad)/.test(ua), */
  isPhone = /(?:iPhone)/.test(ua) && !isTablet,
  isPC = !isPhone && !isAndroid && !isSymbian;
/*   isMobile = isPhone || isAndroid || isSymbian, */
/*   isMicroMessenger = /(?:MicroMessenger)/.test(ua); */

export  default {
    isPC(){
        return isPC
    }
}