import React, { useMemo, useRef } from 'react';
import { Swiper, Pagination, EffectCoverflow, Autoplay } from 'swiper';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import utils from './utils'
import 'swiper/swiper-bundle.min.css';
import './Banner.less';

const Banner = ({ banner }) => {
  const { data } = banner;
  const { menuArr, showType, paginationStyle, autoTimer } = data;
  const swiperObj = useRef()
  const params = useMemo(() => {
    let pms = {
      Swiper,
      modules: [Pagination, EffectCoverflow, Autoplay],
      updateOnWindowResize: true,
      containerClass: "slider",
    };
    if (menuArr.length > 1) {
      pms.autoplay = {
        delay: autoTimer || 3000,
        disableOnInteraction: false,
      }
    }
    return pms;
  }, [menuArr.length, autoTimer]);

  const props = useMemo(() => {
    let sOption = {
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      loop: menuArr.length > 1,
      coverflowEffect: {
        rotate: 0,
        stretch: utils.isPC() ? -90 : -38,
        depth: utils.isPC() ? 200 : 200,
        modifier: 1,
        slideShadows: true,
      },
      updateOnWindowResize: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: paginationStyle === 'default' ? 'bullets' : paginationStyle
      }
    };

    if (showType === '3d-carousel') {
      sOption.effect = 'coverflow'
    }

    return sOption;
  }, [menuArr, paginationStyle])

  return (
    <ReactIdSwiperCustom ref={swiperObj} {...params} {...props} rebuildOnUpdate shouldSwiperUpdate >
      {
        menuArr.map((item, i) => (
          <a
            key={i}
            target="_blank"
            className={` ${showType === '3d-carousel' ? 'slider_3d_carousel' : 'slider'}`}
            href={item.imageLink}
          >
            <img
              src={item.image}
              style={{ height: menuArr[0]?.imageHeight * ((utils.isPC() ? (showType === '3d-carousel' ? 810 : 900) : (showType === '3d-carousel' ? window.innerWidth - 48 : window.innerWidth)) / menuArr[0]?.imageWidth) }}
              alt="轮播"
            />
            <div className={`box ${item.center ? 'center' : ''}`}>
              {item.contentShow && <p className="subtitle">{item.subtitle}</p>}
              {item.contentShow && <p className="title">{item.title}</p>}
              {item.contentShow && <p className="desc">{item.desc}</p>}
              {
                item.contentShow && item.link.show ?
                  <a
                    className="link" href={item.link.src}
                    onClick={(e) => { if (item.link.src === '') { e.preventDefault() } }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{item.link.text}</a> :
                  null
              }
            </div>
          </a>
        ))
      }
    </ReactIdSwiperCustom>
  )
}



export default Banner
