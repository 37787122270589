import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import axios from "axios";
import dayjs from "dayjs";
import "antd/dist/reset.css";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
const duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

function queryStr(name) {
  const match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

function is_wechat_qq() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger\/[0-9]/i)) {
    return "wechat";
  }

  if (ua.match(/QQ\/[0-9]/i)) {
    return "QQ";
  }

  return false;
}

function isHttps() {
  return window.location.href.indexOf("https") === -1;
}

let id = queryStr("page") || window.location.pathname.split("/")[3];
let accessToken = window.location.hash.split("&")[0].split("=")[1];
if (accessToken) {
  localStorage.setItem("ACCESS_TOKEN", accessToken);
  window.location.href = `//live.shangzhibo.tv/w/page/${id}`;
}

const host =
  location.origin.indexOf("localhost") > -1
    ? "//shangzhibo.tv"
    : location.origin;

if (!id) {
  alert("未找到此门户页，请检查地址");
} else {
  postUA(id, `/api/page/${id}`);
  getData();
}

function postUA(id, arg) {
  axios.post(`${host}/api/page/${id}/log`, {
    referer: document.referrer,
    originalUrl: arg,
    host: window.location.host,
  });
}

function getData() {
  const getContent = axios.get(`${host}/api/page/${id}`);
  const getColumns = axios.get(`${host}/api/page/${id}/list?pageSize=999`);

  const getWeChatShareConfig = axios.get(
    `${host}/api/page/${id}/wechat-share-config?url=${encodeURIComponent(
      window.location.href.split("#")[0]
    )}`
  );

  Promise.all([getContent, getColumns])
    .then((datas) => {
      const content = datas[0].data.data;
      const { componentArr, config } = content;
      const wx = window.wx;
      window.document.title = datas[0].data.title;

      if (Object.keys(content).length === 0) {
        window.alert("未检测到门户页数据，请检查控制台是否保存！");
      }

      if (typeof wx !== "undefined" && wx.config) {
        const filterArr = componentArr.filter((v) => v.type === "slogan");
        const slogan = filterArr[0] ? filterArr[0].data : null;

        let shareData = {
          title: datas[0].data.title || "", // 分享标题
          desc: slogan && slogan.desc ? slogan.desc : "", // 分享描述
          link: window.location.href.split("#")[0] || "", // 分享链接
          imgUrl: isHttps()
            ? _.get(slogan, "logo", "").replace("http://", "https://")
            : slogan
            ? slogan.logo
            : "", // 分享图标
        };
        if (is_wechat_qq() === "wechat") {
          if (config.share && config.share.wechat) {
            shareData.desc = config.share.wechat.desc
              ? config.share.wechat.desc
              : shareData.desc;
            shareData.imgUrl = config.share.wechat.logo
              ? isHttps()
                ? config.share.wechat.logo.replace("http://", "https://")
                : config.share.wechat.logo
              : shareData.logo;
          }
        }

        getWeChatShareConfig.then((res) => {
          wx.config({ ...res.data });
          wx.ready(function () {
            wx.updateTimelineShareData(shareData);
            wx.updateAppMessageShareData(shareData);
          });
        });
      }

      if (config?.icon || datas[0]?.data?.icon) {
        document.getElementsByTagName("link")[0].href =
          config?.icon || datas[0].data.icon;
      }

      ReactDOM.render(
        <App
          content={content}
          columns={datas[1].data.items}
          id={id}
          data={datas[0].data}
          loginUrl={datas[0].data.loginUrl}
        />,
        document.getElementById("root")
      );
    })
    .catch((err) => {
      console.log(err);
    });
}
registerServiceWorker();
