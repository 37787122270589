import axios from "axios";

const host =
  location.origin.indexOf("localhost") > -1
    ? "//shangzhibo.tv"
    : location.origin;

export function getActivities({ id, pageSize, orderBy }) {
  return axios.get(
    `${host}/api/page/${id}/activities?pageSize=${pageSize}&orderBy=${orderBy}`
  );
}

export function getLiveActivities({ id, pageSize }) {
  return axios.get(`${host}/api/page/${id}/activity/live?pageSize=${pageSize}`);
}

export function getUserToken({ id, accessToken }) {
  return axios.post(`${host}/api/page/${id}/token`, {
    token: accessToken,
  });
}

export function getUserData({ token }) {
  return axios.get(`${host}/api/user/me`, {
    headers: {
      authorization: `bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
}

export function getUserInfo() {
  return axios.get(`${host}/api/user/me`);
}
