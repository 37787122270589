import React, { Component } from "react";
import PropTypes from "prop-types";
import utils from "./utils";
import Banner from "./Banner";
import List from "./List";
import {
  getActivities,
  getLiveActivities,
  getUserToken,
  getUserData,
  getUserInfo,
} from "./service";
import "./App.css";
import "./App.less";
import menuIcon from "./assets/mobile-menu.png";
import defaultLogo from "./assets/page-logo.svg";
import backTopIcon from "./assets/pic_up.png@2x.png";
import qrCodeIcon from "./assets/pic_scan code.png@2x.png";
import MoreActivity from "./MoreActivity";
import Activity from "./Activity";
import Catalog from "./Catalog";
import Elevator from "./Elevator";
import Countdown from "./Countdown";
import Slogan from "./Slogan";
import KF from "./KF";
import Brand from "./Brand";
import Player from "./Player";
import Search from "./Search";
import SearchContent from "./SearchContent";

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 5,
      index: 0,
      showMenu: false,
      hotActivities: [],
      newActivities: [],
      liveActivities: [],
      showMoreActivity: false,
      user: {},
      componentIndex: null,
      changeAct: "home",
      getForToken: "",
      loginId: null,
      userInfo: {},
      search: false,
    };
  }

  componentDidMount() {
    this.getLive();
    this.getHot();
    this.getNew();
    this.removeMobileHover();
    this.getToken();
    this.getUser();
  }

  toggleMenu() {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  }

  getHot() {
    const { id } = this.props;
    getActivities({ id, pageSize: 99, orderBy: "popular" }).then(({ data }) => {
      this.setState({
        hotActivities: data.items,
      });
    });
  }
  getNew() {
    const { id } = this.props;
    getActivities({ id, pageSize: 99, orderBy: "latest" }).then(({ data }) => {
      this.setState({
        newActivities: data.items,
      });
    });
  }
  getLive() {
    const { id } = this.props;
    getLiveActivities({ id, pageSize: 99 }).then(({ data }) => {
      this.setState({
        liveActivities: data.items,
      });
    });
  }

  getToken() {
    if (localStorage.getItem("ACCESS_TOKEN")) {
      const { id } = this.props;
      getUserToken({
        id,
        accessToken: localStorage.getItem("ACCESS_TOKEN"),
      }).then((res) => {
        if (res.data.accessToken) {
          this.setState({
            getForToken: res.data.accessToken,
          });
          getUserData({ token: res.data.accessToken }).then((result) => {
            this.setState({
              user: result.data,
            });
          });
        }
      });
    }
  }

  getUser() {
    getUserInfo().then((result) => {
      this.setState(
        {
          loginId: result.data.id,
          userInfo: result.data,
        },
        () => {}
      );
    });
  }

  seeMore = (i) => {
    this.setState(
      {
        componentIndex: i,
      },
      () => {
        this.setState({
          showMoreActivity: true,
        });
      }
    );
    document.getElementById("content").scrollTop = 0;
  };

  backIndex = () => {
    this.setState({
      showMoreActivity: false,
    });
  };

  removeMobileHover = () => {
    if (!utils.isPC()) {
      document.body.addEventListener("touchstart", function () {});
    }
  };

  render() {
    const { content, columns, id, data, loginUrl } = this.props;
    const {
      poweredByText,
      poweredByUrl,
      isPagePoweredByEnabled,
      isHome,
      agentId,
      isPartnerLogin,
      registerUrl,
      isBottomNavEnabled,
    } = data;
    const { componentArr, config } = content;
    const {
      newActivities,
      hotActivities,
      liveActivities,
      user,
      showMenu,
      getForToken,
      loginId,
      userInfo,
      search,
    } = this.state;
    const skipHref = `${location.origin}/w/user?index_url=${location.href}`;
    const isAgentId = [129293, 132546, 127428, 132565].includes(agentId);

    const backTop = () => {
      const scrollTop = document.getElementById("content").scrollTop;
      document.getElementById("content").scrollBy(0, -scrollTop);
    };

    const wxLogin = () => {
      window.location.replace(
        `//shangzhibo.tv/page/login?pageId=${id}&redirect_uri=http%3A%2F%2Flive.shangzhibo.tv%2F${id}`
      );
    };

    const userLogOut = () => {
      localStorage.removeItem("ACCESS_TOKEN");
      window.location.href = `//live.shangzhibo.tv/w/page/${id}`;
    };
    const userLogin = () => {
      window.location.href = registerUrl ? registerUrl : loginUrl;
    };
    const userOut = () => {
      window.location.href = `/api/logout?redirect_uri=${encodeURIComponent(
        window.location.href
      )}`;
    };
    const registerAndLogin = () => {
      window.location.href = loginUrl;
    };

    const handAct = (name) => {
      this.setState({
        changeAct: name,
      });
    };

    return (
      <>
        {search ? (
          <SearchContent
            id={id}
            componentArr={componentArr}
            isShowPc={utils.isPC()}
            backIndex={() => {
              this.setState({ search: false });
            }}
          />
        ) : (
          <div
            id="content"
            className="content"
            style={{
              paddingBottom: isHome && isBottomNavEnabled ? 60 : 0,
            }}
          >
            <div
              style={{
                width: "100%",
                height:
                  componentArr[0].type === "header"
                    ? utils.isPC()
                      ? 102
                      : 40
                    : 0,
                display: this.state.showMoreActivity ? "none" : "block",
              }}
            ></div>
            {componentArr.map((obj, index) => (
              <div key={index + 1} id={`${obj?.data?.partNumber ?? ""}`}>
                <div
                  style={{
                    display: this.state.showMoreActivity ? "none" : "block",
                  }}
                >
                  {obj.type === "header" ? (
                    <div>
                      <header
                        className="header"
                        style={{
                          position: index === 0 ? "fixed" : "",
                          display:
                            index !== 0 && this.state.showMoreActivity
                              ? "none"
                              : "",
                          boxShadow: utils.isPC() ? "" : "none",
                        }}
                      >
                        <div className="max">
                          {obj.data.logo ? (
                            <img
                              className="logo"
                              src={obj.data.logo}
                              alt=""
                              onClick={this.backIndex}
                            />
                          ) : (
                            <img
                              className="logo"
                              src={defaultLogo}
                              alt=""
                              onClick={this.backIndex}
                            />
                          )}

                          <nav
                            style={{
                              display:
                                (utils.isPC() && !config.isShowHeaderNav) ||
                                (utils.isPC() &&
                                  config.isShowHeaderNav &&
                                  config.isShowHeaderNav === "show")
                                  ? "block"
                                  : "none",
                            }}
                          >
                            {obj.data.nav.length
                              ? obj.data.nav.map((item, i) =>
                                  item.name ? (
                                    <a
                                      key={i}
                                      href={item.link}
                                      onClick={(e) => {
                                        if (item.link === "")
                                          e.preventDefault();
                                      }}
                                      target={item.target ? "_blank" : ""}
                                    >
                                      {item.name}
                                    </a>
                                  ) : null
                                )
                              : null}
                          </nav>
                          <div
                            className="loginBox"
                            style={{
                              display:
                                utils.isPC() && isHome && !loginId
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <span
                              onClick={registerAndLogin}
                              className="loginBtn"
                              style={{
                                display: isPartnerLogin ? "block" : "none",
                                color: isAgentId ? "#E5133A" : "",
                              }}
                            >
                              登录
                            </span>
                            <button
                              onClick={userLogin}
                              style={{
                                display: isPartnerLogin
                                  ? "inline-block"
                                  : "none",
                                background: isAgentId ? "#E5133A" : "",
                              }}
                            >
                              立即注册
                            </button>
                            <button
                              onClick={registerAndLogin}
                              style={{
                                display: isPartnerLogin
                                  ? "none"
                                  : "inline-block",
                                background: isAgentId ? "#E5133A" : "",
                              }}
                            >
                              登录/注册
                            </button>
                          </div>
                          <div
                            className="userBox"
                            style={{
                              display:
                                utils.isPC() && isHome && loginId
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            {/* <img src={userInfo?.avatar || 'https://doc.shangzhibo.tv/system/activity/template/default-preview.jpg'}
                                alt=""
                              /> */}
                            <p className="nickname">
                              {userInfo?.nickname
                                ? userInfo?.nickname.length > 4
                                  ? userInfo?.nickname.substring(0, 4) + "..."
                                  : userInfo?.nickname
                                : ""}
                            </p>
                            <p
                              className="logout"
                              style={{ color: isAgentId ? "#E5133A" : "" }}
                              onClick={userOut}
                            >
                              退出
                            </p>
                          </div>
                          <img
                            className="menu"
                            style={{
                              display:
                                ((!utils.isPC() && !config.isShowHeaderNav) ||
                                  (!utils.isPC() &&
                                    config.isShowHeaderNav &&
                                    config.isShowHeaderNav === "show")) &&
                                obj.data.nav.length > 0
                                  ? ""
                                  : "none",
                            }}
                            src={menuIcon}
                            alt=""
                            onClick={this.toggleMenu.bind(this)}
                          />
                        </div>
                      </header>
                      {this.state.showMenu ? (
                        <section className="menu_modal">
                          <div className="menu_modal_container">
                            <p className="close">
                              <span onClick={this.toggleMenu.bind(this)}>
                                ×
                              </span>
                            </p>
                            {obj.data.nav.map((item, i) => (
                              <a
                                key={i}
                                href={item.link}
                                onClick={(e) => {
                                  if (item.link === "") e.preventDefault();
                                }}
                                target={item.target ? "_blank" : ""}
                              >
                                {item.name}
                              </a>
                            ))}
                            <a
                              href={loginUrl}
                              style={{
                                display: isHome && !loginId ? "" : "none",
                              }}
                            >
                              登录
                            </a>
                            <a
                              href={`/api/logout?redirect_uri=${encodeURIComponent(
                                window.location.href
                              )}`}
                              style={{
                                display: isHome && loginId ? "" : "none",
                              }}
                            >
                              退出登录
                            </a>
                          </div>
                        </section>
                      ) : null}
                    </div>
                  ) : null}
                  {obj.type === "banner" ? (
                    <section
                      className={"banner"}
                      style={{
                        height:
                          obj?.data?.menuArr[0]?.imageHeight *
                          ((utils.isPC()
                            ? obj?.data?.showType === "3d-carousel"
                              ? 810
                              : 900
                            : obj?.data?.showType === "3d-carousel"
                            ? window.innerWidth - 48
                            : window.innerWidth) /
                            obj?.data?.menuArr[0]?.imageWidth),
                      }}
                    >
                      <Banner banner={obj} />
                    </section>
                  ) : null}
                  {obj.type === "mobilesNav" ? (
                    <ul
                      className={`mobilesNav`}
                      style={{
                        display: !utils.isPC() ? "flex" : "none",
                        minHeight:
                          obj.data.navStyle && obj.data.navStyle === "text"
                            ? 40
                            : "",
                      }}
                    >
                      {obj.data.menuArr.length
                        ? obj.data.menuArr.map((item, i) => (
                            <li
                              key={i}
                              style={{
                                width:
                                  obj.data.showType === "oneLine&3"
                                    ? "33%"
                                    : obj.data.showType === "oneLine&4"
                                    ? "25%"
                                    : "20%",
                              }}
                            >
                              <a
                                href={item.url}
                                onClick={(e) => {
                                  if (item.url === "") e.preventDefault();
                                }}
                                target={item.blank ? "_blank" : ""}
                              >
                                <figure>
                                  <img
                                    src={item.src}
                                    alt="图文导航"
                                    style={{
                                      height:
                                        obj.data.navStyle &&
                                        obj.data.navStyle === "text"
                                          ? 0
                                          : "",
                                      margin:
                                        obj.data.navStyle &&
                                        obj.data.navStyle === "text"
                                          ? 0
                                          : "",
                                      borderRadius:
                                        obj.data.borderStyle &&
                                        obj.data.borderStyle === "square"
                                          ? 0
                                          : obj.data.borderStyle === "fillet"
                                          ? "16px"
                                          : "50%",
                                    }}
                                  />
                                  <figcaption>{item.name}</figcaption>
                                </figure>
                              </a>
                            </li>
                          ))
                        : null}
                    </ul>
                  ) : null}
                  {obj.type === "contents" ? (
                    <main className="main">
                      {obj.data.map((ct, index) =>
                        ct.show ? (
                          <List
                            isShowPc={utils.isPC()}
                            content={ct}
                            columns={columns}
                            liveActivities={liveActivities}
                            hotActivities={hotActivities}
                            newActivities={newActivities}
                            key={index}
                            seeMore={this.seeMore}
                          />
                        ) : null
                      )}
                    </main>
                  ) : null}
                  {obj.type === "graphic" ? (
                    <section
                      className={`graphicwrap`}
                      style={{
                        maxWidth: utils.isPC() ? 900 : "100%",
                        marginLeft: utils.isPC() ? "calc(50% - 450px)" : 0,
                      }}
                    >
                      <div className={`graphicwrap_richTextBox simditor`}>
                        <div
                          className={`graphicwrap_richTextBox__simditorbody`}
                          dangerouslySetInnerHTML={{
                            __html: obj?.data?.richText || "",
                          }}
                        ></div>
                      </div>
                    </section>
                  ) : null}
                  {obj.type === "slogan" && obj?.data?.show !== false && (
                    <Slogan obj={obj} isShowPc={utils.isPC()} />
                  )}
                  {["activity", "activity-tab", "card", "video"].includes(
                    obj.type
                  ) ? (
                    <Activity
                      obj={obj}
                      isShowPc={utils.isPC()}
                      showMoreActivity={this.state.showMoreActivity}
                      index={index}
                      id={id}
                      seeMore={this.seeMore}
                    />
                  ) : null}
                  {obj.type === "catalog" && (
                    <Catalog
                      obj={obj}
                      isShowPc={utils.isPC()}
                      index={index}
                      seeMore={this.seeMore}
                      id={id}
                    />
                  )}
                  {obj.type === "sudokuNav" ? (
                    <ul
                      className={`sudokuNav`}
                      style={{
                        minHeight:
                          obj.data.navStyle && obj.data.navStyle === "text"
                            ? 40
                            : "",
                      }}
                    >
                      {obj.data.menuArr.length
                        ? obj.data.menuArr.map((item, i) => (
                            <li
                              key={i}
                              style={{
                                width: "33%",
                              }}
                            >
                              <a
                                href={item.url}
                                onClick={(e) => {
                                  if (item.url === "") e.preventDefault();
                                }}
                                target={item.blank ? "_blank" : ""}
                              >
                                <figure>
                                  <img
                                    src={item.src}
                                    alt="九宫格"
                                    style={{
                                      height:
                                        obj.data.navStyle &&
                                        obj.data.navStyle === "text"
                                          ? 0
                                          : "",
                                      margin:
                                        obj.data.navStyle &&
                                        obj.data.navStyle === "text"
                                          ? 0
                                          : "",
                                      borderRadius:
                                        obj.data.borderStyle &&
                                        obj.data.borderStyle === "square"
                                          ? 0
                                          : obj.data.borderStyle === "fillet"
                                          ? "16px"
                                          : "50%",
                                    }}
                                  />
                                  <figcaption>{item.name}</figcaption>
                                </figure>
                              </a>
                            </li>
                          ))
                        : null}
                    </ul>
                  ) : null}
                  {obj.type === "elevator" ? (
                    <Elevator
                      componentArr={componentArr}
                      obj={obj}
                      isShowPc={utils.isPC()}
                      agentId={agentId}
                    />
                  ) : null}
                  {obj.type === "countdown" && (
                    <Countdown
                      obj={obj}
                      isShowPc={utils.isPC()}
                      index={index}
                    />
                  )}
                  {obj.type === "brand" && (
                    <Brand obj={obj} isShowPc={utils.isPC()} />
                  )}
                  {obj.type === "player" && (
                    <Player obj={obj} isShowPc={utils.isPC()} />
                  )}
                  {obj.type === "search" && (
                    <Search
                      onSearch={() => {
                        this.setState({ search: true });
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
            <div
              style={{
                display: this.state.showMoreActivity ? "block" : "none",
                padding: !utils.isPC() ? "15px 0 20px" : "30px 0 20px",
              }}
              className="moreActivity"
            >
              {this.state.componentIndex !== null ? (
                <MoreActivity
                  componentArr={content.componentArr}
                  componentIndex={this.state.componentIndex}
                  id={id}
                  isShowPc={utils.isPC()}
                  backIndex={this.backIndex}
                />
              ) : null}
            </div>
            {isHome && isBottomNavEnabled ? (
              <div
                className="bot_bar"
                style={{
                  maxWidth: utils.isPC() ? 1000 : "100%",
                  marginLeft: utils.isPC() ? "calc(50% - 500px)" : 0,
                }}
              >
                <div
                  onClick={() => handAct("home")}
                  className={this.state.changeAct === "home" ? "tabs_link" : ""}
                >
                  <a>
                    <i className="iconfont ">&#xe621;</i>
                    <em className="iconfont icon_tab_home_selected">
                      &#xe623;
                    </em>
                    <span className="text_change">首页</span>
                  </a>
                </div>
                <div
                  onClick={() => handAct("me")}
                  className={this.state.changeAct === "me" ? "tabs_link" : ""}
                >
                  <a href={loginId ? skipHref : loginUrl}>
                    <i className="iconfont ">&#xe624;</i>
                    <em className="iconfont icon_tab_mine_selected">
                      &#xe625;
                    </em>
                    <span className="text_change"> 我的 </span>
                  </a>
                </div>
              </div>
            ) : null}
            <footer
              style={{ display: isPagePoweredByEnabled ? "" : "none" }}
              className="footer"
            >
              <a
                href={poweredByUrl || "//shangzhibo.tv"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Powered By {poweredByText || "Shangzhibo"}
              </a>
            </footer>
            <KF data={config?.sidebar?.kf ?? {}} />
            <div
              className="sidebar"
              style={{ display: utils.isPC() ? "" : "none" }}
            >
              <div
                className="sidebar__qrCode"
                style={{
                  display: config?.sidebar?.wechat?.isWeChatShow ? "" : "none",
                }}
              >
                <img src={qrCodeIcon} alt="" />
                <div className="sidebar__qrCode__wrap">
                  <img src={config?.sidebar?.wechat?.logo} alt="" />
                  <p> {config?.sidebar?.wechat?.desc || "扫码关注官方微信"} </p>
                </div>
              </div>
              <div className="sidebar__sideTop" onClick={backTop}>
                <img src={backTopIcon} alt="" />
                <div
                  className="sidebar__sideTop__text"
                  style={{
                    bottom: config?.sidebar?.wechat?.isWeChatShow ? 4 : 62,
                  }}
                >
                  <span>回到顶部</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

Preview.bannerPlayFalg = null;

Preview.propTypes = {
  content: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default Preview;
