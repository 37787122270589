import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Brand.less";

const Brand = ({ obj, isShowPc }) => {
  const { data } = obj;
  const {
    name,
    avatar,
    fansOrFollow,
    heatOrPv,
    fansOrFollowValue,
    heatOrPvValue,
    color,
    showFansAndPv,
    followSet,
  } = data;
  const [followed, setFollowed] = useState(
    localStorage.getItem("BRAND_FOLLOW")
  );
  return (
    <div className="brand">
      <img src={avatar} alt="" />
      <div>
        <p className="name">{name}</p>
        {showFansAndPv && (
          <p className="detail">
            {fansOrFollowValue && (
              <>
                <span className="value" style={{ color }}>
                  {fansOrFollowValue}
                </span>
                <span className="attribute">
                  {fansOrFollow === "fans" ? "粉丝" : "关注"}
                </span>
              </>
            )}
            {heatOrPvValue && (
              <>
                <span className="value" style={{ color }}>
                  {heatOrPvValue}
                </span>
                <span className="attribute">
                  {heatOrPv === "heat" ? "热度" : "访问"}
                </span>
              </>
            )}
          </p>
        )}
      </div>
      {followSet === "fake" && (
        <button
          disabled={followed}
          onClick={() => {
            if (!followed) {
              setFollowed(true);
              localStorage.setItem("BRAND_FOLLOW", "true");
            }
          }}
          style={{
            border: followed ? "1px solid #C9CDD4" : `solid 1px ${color}`,
            color: followed ? "#86909d" : color,
          }}
        >
          {followed ? "已关注" : "关注"}
        </button>
      )}
    </div>
  );
};

Brand.propTypes = {};

export default Brand;
