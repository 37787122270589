import React, { useState } from "react";
import moment from "moment";
import "moment/locale/zh-cn";
import playingIcon from "./assets/playing.gif";

moment.locale("zh-cn");

function List({
  content,
  columns,
  liveActivities,
  hotActivities,
  newActivities,
  seeMore,
  isShowPc,
}) {
  const isTab = content.layout === "tab";
  const main = content.data;
  main.cardIds = main.cardIds || [];
  const isActList = content.type === "activities";
  const arr = (isActList ? main.listIds : main.cardIds).map((m) =>
    columns.find((n) => n.id === m)
  );
  const lists = arr.filter((item) => item !== undefined);
  let tabs = [];

  if (
    isActList &&
    main.liveActivities &&
    main.liveActivities.show &&
    liveActivities.length > 0
  ) {
    tabs.push({
      name: isTab ? "直播" : "正在直播",
      activities: liveActivities,
      quantity: main.liveActivities.num,
    });
  }

  if (isActList && main.newActivities.show) {
    tabs.push({
      name: isTab ? "最新" : "最新活动",
      activities: newActivities,
      quantity: main.newActivities.num,
    });
  }

  if (isActList && main.hotActivities.show) {
    tabs.push({
      name: isTab ? "热门" : "热门活动",
      activities: hotActivities,
      quantity: main.hotActivities.num,
    });
  }

  const timeCheck = (startedAt, endedAt) => {
    const now = Date.now();
    const notStart = new Date(startedAt) > now;
    const finished = new Date(endedAt) < now;

    if (notStart) {
      return "notStart";
    }
    if (finished) {
      return "finished";
    }
    return "onGoing";
  };

  tabs = tabs.concat(lists);
  tabs = tabs.length ? tabs : [{}];
  const [tab, setTab] = useState(0);
  const getPv = (pv) => (pv >= 100000 ? `${parseInt(pv / 10000, 10)}w` : pv);

  const getCards = (type, card, data, num) =>
    (data || []).map((item, i) => {
      let now = new Date().getTime();
      const isBegin = now > new Date(item.startedAt).getTime();
      const isOver = now > new Date(item.endedAt).getTime();
      const isLiving = item.isPushing && isBegin && !isOver; // 直播中
      if (i < num) {
        const t = item.template;
        return type === "activities" ? (
          <li className={`${type} ${card}`} key={i}>
            <a href={item.link || item.hyperlink} target="_blank">
              <div
                className="cover"
                style={{ backgroundImage: `url('${t.imgCover}')` }}
              >
                <div className="modal" style={{ opacity: isLiving ? "" : 0 }}>
                  <div className="special">
                    <img src={playingIcon} alt="" />
                    <div>正在直播</div>
                  </div>
                </div>
              </div>
              <div className="desc">
                <div className="left">
                  <p
                    className="tt"
                    style={{
                      lineHeight: isShowPc ? "24px" : "",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {item ? item.name : ""}
                  </p>
                  <div className="ct">
                    <div>
                      <div
                        className="icon"
                        style={{
                          background: isLiving
                            ? "#dfedff"
                            : timeCheck(item.startedAt, item.endedAt) ===
                              "notStart"
                            ? "#def6e1"
                            : timeCheck(item.startedAt, item.endedAt) ===
                              "onGoing"
                            ? "#dfedff"
                            : "#fff4ea",
                          color: isLiving
                            ? "#2282ff"
                            : timeCheck(item.startedAt, item.endedAt) ===
                              "notStart"
                            ? "#2cb34f"
                            : timeCheck(item.startedAt, item.endedAt) ===
                              "onGoing"
                            ? "#2282ff"
                            : "#f57e09",
                        }}
                      >
                        {isLiving
                          ? "直播"
                          : timeCheck(item.startedAt, item.endedAt) ===
                            "notStart"
                          ? "预约"
                          : timeCheck(item.startedAt, item.endedAt) ===
                            "onGoing"
                          ? "直播"
                          : "回看"}
                      </div>
                      <div
                        className="time"
                        style={{
                          fontSize: isShowPc ? 16 : "",
                          color:
                            timeCheck(item.startedAt, item.endedAt) ===
                              "onGoing" || isLiving
                              ? "#2282ff"
                              : "#999",
                        }}
                      >
                        {["onGoing", "notStart"].includes(
                          timeCheck(item.startedAt, item.endedAt)
                        )
                          ? moment(item.startedAt).calendar(null, {
                              sameElse: "YYYY年M月DD日",
                              nextWeek: "YYYY年M月DD日",
                              lastWeek: "YYYY年M月DD日",
                            })
                          : `${moment(item.startedAt).format("YYYY年M月DD日")}`}
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="price"
                        style={{
                          fontSize: isShowPc ? 18 : "",
                          marginRight:
                            card === "horizontal" &&
                            t &&
                            t.auth &&
                            t.auth.methods
                              .map((auth) => auth[0])
                              .indexOf("pay") > -1
                              ? 8
                              : 0,
                          marginLeft:
                            card !== "horizontal" &&
                            t &&
                            t.auth &&
                            t.auth.methods
                              .map((auth) => auth[0])
                              .indexOf("pay") > -1
                              ? 70
                              : 0,
                        }}
                      >
                        {t.auth &&
                        t.auth.methods.map((auth) => auth[0]).indexOf("pay") >
                          -1
                          ? `￥${t.auth.payPrice}`
                          : " "}
                      </div>
                      <span
                        className="uv"
                        style={{
                          display:
                            (content.showUV && content.showUV === "off") ||
                            timeCheck(item.startedAt, item.endedAt) ===
                              "notStart"
                              ? "none"
                              : "inline-block",
                        }}
                      >
                        {getPv(item.pv || 0)}人观看
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        ) : (
          <li className={`${type} ${card}`} key={i}>
            <a href={item.hyperlink} target="_blank">
              <div className="cover">
                <img
                  src={item.background}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>
              {item.title ? (
                <div className="desc">
                  <p className="tt">{item.title}</p>
                </div>
              ) : null}
            </a>
          </li>
        );
      }
      return null;
    });

  const lineList = (
    <React.Fragment>
      {tabs.map((item, i) => (
        <div
          style={{
            display: item && item.quantity ? "block" : "none",
            paddingTop: isShowPc ? 50 : 30,
          }}
          key={i}
        >
          <h2>{item ? item.name : ""}</h2>
          <ul className="lists">
            {getCards(
              content.type,
              content.card,
              isActList
                ? item
                  ? item.activities
                  : []
                : item
                ? item.items
                : [],
              item ? (item.quantity > 6 ? 6 : item.quantity) : 0
            )}
          </ul>
          {/* <p
                            style={{
                                display: (item && item.activities && item.activities.length > item.quantity) || (item && item.items && item.items.length > item.quantity)
                                    ? 'flex' : 'none',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 35,
                            }}
                        >
                            <button className="ghost" style={{ height: !isShowPc ? 45 : 55, width: !isShowPc ? 218 : 273, fontSize: !isShowPc ? 15 : 18 }} onClick={seeMore}>查看更多</button>
                        </p> */}
        </div>
      ))}
    </React.Fragment>
  );

  const tabList = (
    <React.Fragment>
      <ul className="tabs">
        {tabs.map((item, i) => (
          <li
            key={i}
            className={i === tab ? "active" : ""}
            onClick={() => setTab(i)}
          >
            {item ? item.name : ""}
          </li>
        ))}
      </ul>
      <div
        style={{
          display: tabs[tab] && tabs[tab].quantity ? "block" : "none",
          paddingTop: 0,
        }}
      >
        <ul className="lists">
          {getCards(
            content.type,
            content.card,
            isActList
              ? tabs[tab]
                ? tabs[tab].activities
                : []
              : tabs[tab]
              ? tabs[tab].items
              : [],
            tabs[tab] ? (tabs[tab].quantity > 6 ? 6 : tabs[tab].quantity) : 0
          )}
        </ul>
        {/* <p
                    style={{
                        display: tabs[tab] && tabs[tab].items && tabs[tab].items.length > tabs[tab].quantity
                            ? 'block' : 'none',
                    }}
                >
                    <button className="ghost" style={{ height: !isShowPc ? 45 : 55, width: !isShowPc ? 218 : 273, fontSize: !isShowPc ? 15 : 18 }} onClick={seeMore}>查看更多</button>
                </p> */}
      </div>
    </React.Fragment>
  );

  return (
    <div className="list" style={{ paddingTop: isShowPc && isTab ? 50 : "" }}>
      {isTab ? tabList : lineList}
    </div>
  );
}

export default List;
