import React, { useState } from "react";
import { Modal } from "antd";
import kfIcon from "./assets/icon_kf.png";
import telIcon from "./assets/icon_phone.png";
import utils from "./utils";
import "./KF.less";

const KF = ({ data }) => {
  const [modalVisible, toggleModalVisible] = useState(false);
  if (!data?.enable) return null;

  return (
    <div
      className="kf"
      style={{
        bottom: utils.isPC() ? 150 : 75,
        right: utils.isPC() ? 20 : 0,
      }}
    >
      {data?.type === "link" ? (
        <a href={data?.link} target="_blank" rel="noreferrer">
          <img src={kfIcon} style={{ width: 80 }} />
        </a>
      ) : (
        <>
          <img
            src={kfIcon}
            onClick={() => {
              toggleModalVisible(true);
            }}
            style={{ width: 80, cursor: "pointer" }}
          />
          <Modal
            centered
            open={modalVisible}
            onCancel={() => {
              toggleModalVisible(false);
            }}
            title={null}
            footer={null}
            closable={false}
            wrapClassName="kf-modal"
            width={255}
          >
            {data?.title && <p className="title">{data?.title}</p>}
            {data?.qrcode && <img className="qrcode" src={data?.qrcode} />}
            {data?.desc && <p className="desc">{data?.desc}</p>}
            {data?.tel && (
              <a href={`tel:${data?.tel}`} className="tel">
                <img src={telIcon} />
                {data?.tel}
              </a>
            )}
          </Modal>
        </>
      )}
    </div>
  );
};

export default KF;
