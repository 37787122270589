import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import playingIcon from "./assets/playing.gif";

const host =
  location.origin.indexOf("localhost") > -1
    ? "//shangzhibo.tv"
    : location.origin;

const MoreActivity = ({
  componentArr,
  componentIndex,
  id,
  isShowPc,
  backIndex,
}) => {
  const [tab, setTab] = useState(0);
  const [reqPage, setReqPage] = useState(1);
  const [pager, setPager] = useState({ page: 1, pageSize: 10, total: 0 });
  const [showList, setShowList] = useState([]);
  const { data, type } = componentArr[componentIndex];
  const { menuArr, showType, showUV, showPrice, showTag = "content" } = data;
  const [catalogActivities, setCatalogActivities] = useState([]);
  const isAndroid = window.navigator.userAgent.indexOf("Android") > -1;
  const getCatalogs = (arr) => {
    if (arr.length > 0) {
      axios
        .get(`${host}/api/page/${id}/catalog/list?catalogIds=${arr.join(",")}`)
        .then((res) => {
          setShowList(res.data);
        });
    }
  };

  const getActivities = (arr) => {
    axios
      .get(`${host}/api/page/${id}/activity/list?activityIds=${arr.join(",")}`)
      .then((res) => {
        setCatalogActivities(res.data);
      });
  };

  useEffect(() => {
    if (
      showList?.length === 1 &&
      showType === "customize" &&
      type === "catalog"
    ) {
      getActivities(showList[0]?.chapters?.[tab].activityIds);
    }
  }, [tab, showList, showType, type]);

  useEffect(() => {
    if (type !== "catalog") {
      setReqPage(1);
      setShowList([]);
      setPager({ page: 1, pageSize: 10, total: 0 });
    } else {
      getCatalogs(data?.catalogs);
    }
  }, [type]);

  useEffect(() => {
    if (["card", "catalog"].includes(type)) return;
    const getActivities = (arr) => {
      if (arr.length > 0) {
        axios
          .get(
            `${host}/api/page/${id}/activity/list?activityIds=${arr.join(",")}`
          )
          .then((res) => {
            setShowList(res.data);
          });
      } else {
        setShowList([]);
      }
    };

    const getActivitiesByGroup = (groupId, page = 1, pageSize = 15) => {
      if (groupId && groupId !== "") {
        axios
          .get(
            type === "activity-tab"
              ? `${host}/api/v3/activity-group/${groupId}/activities?page=${page}&pageSize=${pageSize}`
              : `${host}/api/v3/activity-group/${groupId}/activities?page=${page}&pageSize=${pageSize}&type=${
                  type === "video" ? "video" : "live"
                }`
          )
          .then((res) => {
            setShowList(
              page === 1 ? res.data.items : showList.concat(res.data.items)
            );
            setPager(res.data.pager);
          });
      } else {
        setShowList([]);
      }
    };

    if (menuArr[tab] && menuArr[tab].type === "group") {
      getActivitiesByGroup(menuArr[tab].groupId, reqPage);
    } else {
      getActivities(menuArr[tab].activities);
    }
  }, [tab, menuArr?.[tab], reqPage]);

  const handleInfiniteOnLoad = () => {
    if (pager.page * pager.pageSize >= pager.total) {
      return;
    }

    setReqPage(pager.page + 1);
  };

  const getStyle = (type, st) => ({
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    maxHeight: st === "horizontal" ? 80 : 40,
    height: "auto",
    "white-space": "unset",
    WebkitLineClamp: st === "horizontal" ? 4 : 2,
    margin: type === "card" ? "8px 0 0" : "",
  });

  const calcTotal = (obj) => {
    const { chapters = [] } = obj;
    let num = 0;
    chapters.forEach((itm) => {
      num += itm?.activityIds?.length;
    });
    return num;
  };

  const timeCheck = (startedAt, endedAt) => {
    const now = Date.now();
    const notStart = new Date(startedAt) > now;
    const finished = new Date(endedAt) < now;

    if (notStart) {
      return "notStart";
    }
    if (finished) {
      return "finished";
    }
    return "onGoing";
  };

  const getPv = (pv) => (pv >= 100000 ? `${parseInt(pv / 10000, 10)}w` : pv);

  const getList = (st, data) =>
    (data || []).map((item, i) => {
      const t = item.template;
      let now = new Date().getTime();
      const isBegin = now > new Date(item.startedAt).getTime();
      const isOver = now > new Date(item.endedAt).getTime();
      const isLiving = item.isPushing && isBegin && !isOver; // 直播中
      return (
        <li
          className={`activities ${st}`}
          key={i}
          style={{ boxShadow: type === "card" ? "none" : "" }}
        >
          <a href={item.link} target="_blank">
            <div
              className="cover"
              style={{
                backgroundImage: `url('${t ? t.imgCover : item.image}')`,
              }}
            >
              <div className="modal" style={{ opacity: isLiving ? 1 : 0 }}>
                <div className="special">
                  <img src={playingIcon} alt="" />
                  <div>正在直播</div>
                </div>
              </div>
            </div>
            <div
              className="desc"
              style={{
                height: type === "card" && st === "halfLine" ? "auto" : "",
                padding: type === "card" && st !== "vertical" ? 0 : "",
              }}
            >
              {["halfLine", "oneLine", "twoLine"].includes(st) &&
              type !== "card" ? (
                <span
                  className="uv"
                  style={{
                    display:
                      showUV === "off" ||
                      timeCheck(item.startedAt, item.endedAt) === "notStart"
                        ? "none"
                        : "inline-block",
                  }}
                >
                  {getPv(item.pv || 0)}人在看
                </span>
              ) : null}
              <div className="left">
                <p
                  className="tt"
                  style={type === "card" ? getStyle(type, showType) : {}}
                >
                  {type === "card" ? item.text : item.name}
                </p>
                {type !== "card" && t ? (
                  <div className="ct">
                    <div>
                      <div
                        className="icon"
                        style={{
                          background:
                            showTag === "content"
                              ? item?.type === "video"
                                ? "#f0f3f7"
                                : isLiving
                                ? "#dfedff"
                                : timeCheck(item.startedAt, item.endedAt) ===
                                  "notStart"
                                ? "#def6e1"
                                : timeCheck(item.startedAt, item.endedAt) ===
                                  "onGoing"
                                ? "#dfedff"
                                : "#fff4ea"
                              : `${item?.group?.backgroundColor}`,
                          color:
                            showTag === "content"
                              ? item?.type === "video"
                                ? "#888"
                                : isLiving
                                ? "#2282ff"
                                : timeCheck(item.startedAt, item.endedAt) ===
                                  "notStart"
                                ? "#2cb34f"
                                : timeCheck(item.startedAt, item.endedAt) ===
                                  "onGoing"
                                ? "#2282ff"
                                : "#f57e09"
                              : `${item?.group?.textColor}`,
                        }}
                      >
                        {showTag === "content"
                          ? item?.type === "video"
                            ? "视频"
                            : isLiving
                            ? "直播"
                            : timeCheck(item.startedAt, item.endedAt) ===
                              "notStart"
                            ? "预约"
                            : timeCheck(item.startedAt, item.endedAt) ===
                              "onGoing"
                            ? "直播"
                            : "回看"
                          : `${
                              item?.group?.name
                                ? item?.group?.name.substring(0, 2)
                                : ""
                            }`}
                      </div>
                      <div
                        className="time"
                        style={{
                          color:
                            timeCheck(item.startedAt, item.endedAt) ===
                              "onGoing" || isLiving
                              ? "#2282ff"
                              : "#999",
                          visibility:
                            item?.type === "video" ? "hidden" : "unset",
                        }}
                      >
                        {["onGoing", "notStart"].includes(
                          timeCheck(item.startedAt, item.endedAt)
                        )
                          ? moment(item.startedAt).calendar(null, {
                              sameElse: "YYYY年M月DD日",
                              nextWeek: "YYYY年M月DD日",
                              lastWeek: "YYYY年M月DD日",
                            })
                          : `${moment(item.startedAt).format("YYYY年M月DD日")}`}
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        justifyContent:
                          st === "vertical" &&
                          t &&
                          t.auth &&
                          t.auth.methods.map((auth) => auth[0]).indexOf("pay") >
                            -1
                            ? ""
                            : "flex-start",
                      }}
                    >
                      <div
                        className="price"
                        style={{
                          marginRight:
                            st === "horizontal" &&
                            !isShowPc &&
                            t &&
                            t.auth &&
                            t.auth.methods
                              .map((auth) => auth[0])
                              .indexOf("pay") > -1
                              ? 8
                              : 0,
                        }}
                      >
                        {t &&
                        t.auth &&
                        t.auth.methods.map((auth) => auth[0]).indexOf("pay") >
                          -1
                          ? `￥${t.auth.payPrice}`
                          : ""}
                      </div>
                      {!["halfLine", "oneLine", "twoLine"].includes(st) ? (
                        <span
                          className="uv"
                          style={{
                            display:
                              showUV === "off" ||
                              timeCheck(item.startedAt, item.endedAt) ===
                                "notStart"
                                ? "none"
                                : "inline-block",
                          }}
                        >
                          {getPv(item.pv || 0)}人在看
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </a>
        </li>
      );
    });

  const getCatalogList = (arr) =>
    arr?.map((item) => (
      <li
        key={item.id}
        className={`activities ${
          data?.showType === "customize" ? "horizontal" : data?.showType
        }`}
      >
        <a href={item.link} target="_blank">
          <div
            className="cover"
            style={{
              backgroundImage: `url('${
                data?.showType === "customize"
                  ? item?.template?.imgCover
                  : item?.appearance?.cover
              }')`,
            }}
          />
          <div className="desc">
            <div className="left">
              <p className="tt">{item.name}</p>
              <div className="ct">
                <div>
                  <div
                    className="icon"
                    style={{
                      background:
                        showType === "customize"
                          ? item?.type === "video"
                            ? "#f0f3f7"
                            : timeCheck(item.startedAt, item.endedAt) ===
                              "notStart"
                            ? "#def6e1"
                            : timeCheck(item.startedAt, item.endedAt) ===
                              "onGoing"
                            ? "#dfedff"
                            : "#fff4ea"
                          : "#F6E9FF",
                      color:
                        showType === "customize"
                          ? item?.type === "video"
                            ? "#888"
                            : timeCheck(item.startedAt, item.endedAt) ===
                              "notStart"
                            ? "#2cb34f"
                            : timeCheck(item.startedAt, item.endedAt) ===
                              "onGoing"
                            ? "#2282ff"
                            : "#f57e09"
                          : "#6915a4",
                    }}
                  >
                    {showType === "customize"
                      ? item?.type === "video"
                        ? "视频"
                        : timeCheck(item.startedAt, item.endedAt) === "notStart"
                        ? "预约"
                        : timeCheck(item.startedAt, item.endedAt) === "onGoing"
                        ? "直播"
                        : "回看"
                      : "合辑"}
                  </div>
                  <div
                    className="time"
                    style={{
                      display: data?.showNum === "on" ? "" : "none",
                      color: ["horizontal", "customize"].includes(showType)
                        ? "#999"
                        : "",
                    }}
                  >
                    {showType === "customize"
                      ? ["onGoing", "notStart"].includes(
                          timeCheck(item.startedAt, item.endedAt)
                        )
                        ? moment(item.startedAt).calendar(null, {
                            sameElse: "YYYY年M月DD日",
                            nextWeek: "YYYY年M月DD日",
                            lastWeek: "YYYY年M月DD日",
                          })
                        : `${moment(item.startedAt).format(
                            isAndroid ? "M月DD日" : "YYYY年M月DD日"
                          )}`
                      : `已更新${calcTotal(item)}个活动`}
                  </div>
                </div>
                <div className="row">
                  {showType === "customize" && (
                    <span
                      className="uv"
                      style={{
                        display:
                          timeCheck(item.startedAt, item.endedAt) === "notStart"
                            ? "none"
                            : "inline-block",
                      }}
                    >
                      {getPv(item.pv || 0)}人在看
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </a>
      </li>
    ));

  const renderTabList = (arr) => {
    return arr?.map((item, i) => (
      <li
        key={i}
        className={i === tab ? "active" : ""}
        onClick={() => {
          setTab(i);
          if (type !== "catalog" && arr[i].type === "group") {
            setReqPage(1);
          }
        }}
        style={{ maxWidth: arr?.length === 1 ? "unset" : "" }}
      >
        {item ? item.title || item.selection : ""}
      </li>
    ));
  };

  const tabList = (
    <React.Fragment>
      <div className="tab">
        <span
          onClick={() => {
            setTab(0);
            backIndex();
          }}
          style={{
            height: isShowPc ? 24 : 16,
            width: isShowPc ? 24 : 16,
          }}
        ></span>
        <ul
          className={
            (menuArr && menuArr?.length > 1) ||
            (type === "catalog" && showType === "customize")
              ? "menuArr"
              : "menu"
          }
        >
          {type !== "catalog" ||
          (type === "catalog" && showType === "customize") ? (
            renderTabList(
              type === "catalog" ? showList?.[0]?.chapters : menuArr
            )
          ) : (
            <li>{data?.title}</li>
          )}
        </ul>
      </div>
      <div
        style={{
          display:
            menuArr?.[tab] && menuArr?.[tab]?.num
              ? "block"
              : type === "catalog"
              ? "block"
              : "none",
        }}
      >
        <ul
          className="lists"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            height: "calc(100vh - 94px)",
            overflow: "auto",
          }}
        >
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={handleInfiniteOnLoad}
            hasMore={pager.page * pager.pageSize < pager.total}
            useWindow={false}
            className="lists_box"
          >
            {type !== "catalog"
              ? getList(
                  isShowPc && ["oneLine", "twoLine"].includes(showType)
                    ? "vertical"
                    : showType,
                  type === "card" ? menuArr[tab].items : showList
                )
              : getCatalogList(
                  showType === "customize" ? catalogActivities : showList
                )}
          </InfiniteScroll>
        </ul>
      </div>
    </React.Fragment>
  );

  return (
    <div
      style={{ minHeight: "calc(100vh - 151px)", position: "relative" }}
      className={!isShowPc ? "mobile" : ""}
    >
      {tabList}
    </div>
  );
};

MoreActivity.propTypes = {
  componentArr: PropTypes.array.isRequired,
  componentIndex: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  isShowPc: PropTypes.bool.isRequired,
  backIndex: PropTypes.func.isRequired,
};

export default MoreActivity;
