import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import arrowIcon from "./assets/btn_back@2x.png";
import hotIcon from "./assets/icon_hot@2x.png";
import entryIcon from "./assets/arrow_entry@2x.png";
import searchIcon from "./assets/icon_search.png";
import "./SearchContent.less";

const host =
  location.origin.indexOf("localhost") > -1
    ? "//shangzhibo.tv"
    : location.origin;
const getPv = (pv) => (pv >= 100000 ? `${parseInt(pv / 10000, 10)}w` : pv);
const index = ({ id, componentArr, backIndex }) => {
  const obj = componentArr.find((item) => item.type === "search");
  const { data } = obj;
  const { showKeyWords, searchScope, searchByGroups, keyWords } = data;
  const [searchVal, setSearchVal] = useState("");
  const groupIds = searchByGroups.map((item) => item.id);
  const [searchResult, setSearchResult] = useState(null);
  const [tab, setTab] = useState("activity");
  const isAndroid = window.navigator.userAgent.indexOf("Android") > -1;
  const [searchPage, toggleSearchPage] = useState(false); // 搜索结果页
  const [listPageType, setListPageType] = useState(null); // 列表页

  const onSearch = (name = searchVal) => {
    let groupQuery = "";
    if (searchScope !== "all") {
      groupIds?.forEach((item) => {
        groupQuery += `&groupId=${item}`;
      });
    }
    if (name !== searchVal) {
      setSearchVal(name);
    }
    axios
      .get(
        `${host}/api/v3/activity/search?size=200&pageId=${id}&name=${name}${
          searchScope !== "all" ? groupQuery : ""
        }`
      )
      .then((res) => {
        setSearchResult(res.data);
      });
    toggleSearchPage(true);
  };

  useEffect(() => {
    if (searchVal) {
      onSearch();
    }
  }, [tab]);

  useEffect(
    () => () => {
      setSearchVal("");
      setSearchResult(null);
      toggleSearchPage(false);
      setTab("activity");
    },
    []
  );

  const timeCheck = (startedAt, endedAt) => {
    const now = Date.now();
    const notStart = new Date(startedAt) > now;
    const finished = new Date(endedAt) < now;

    if (notStart) {
      return "notStart";
    }
    if (finished) {
      return "finished";
    }
    return "onGoing";
  };

  const renderList = (data) => {
    return data?.map((item) => (
      <li className={`activities horizontal`} key={item.id}>
        <a target="_blank" href={item.link || item.hyperlink}>
          <div
            className="cover"
            style={{
              backgroundImage: `url('${item.imgCover}')`,
            }}
          />
          <div className="desc">
            <div className="left">
              <p className="tt">{item.name}</p>
              <div className="ct">
                <div>
                  <div
                    className="icon"
                    style={{
                      background:
                        item?.type === "video"
                          ? "#f0f3f7"
                          : timeCheck(item.startedAt, item.endedAt) ===
                            "notStart"
                          ? "#def6e1"
                          : timeCheck(item.startedAt, item.endedAt) ===
                            "onGoing"
                          ? "#dfedff"
                          : "#fff4ea",
                      color:
                        item?.type === "video"
                          ? "#888"
                          : timeCheck(item.startedAt, item.endedAt) ===
                            "notStart"
                          ? "#2cb34f"
                          : timeCheck(item.startedAt, item.endedAt) ===
                            "onGoing"
                          ? "#2282ff"
                          : "#f57e09",
                    }}
                  >
                    {item?.type === "video"
                      ? "视频"
                      : timeCheck(item.startedAt, item.endedAt) === "notStart"
                      ? "预约"
                      : timeCheck(item.startedAt, item.endedAt) === "onGoing"
                      ? "直播"
                      : "回看"}
                  </div>
                  <div
                    className="time"
                    style={{
                      color:
                        timeCheck(item.startedAt, item.endedAt) === "onGoing"
                          ? "#2282ff"
                          : "#999",
                      visibility: item?.type === "video" ? "hidden" : "unset",
                    }}
                  >
                    {["onGoing", "notStart"].includes(
                      timeCheck(item.startedAt, item.endedAt)
                    )
                      ? moment(item.startedAt).calendar(null, {
                          sameElse: "YYYY年M月DD日",
                          nextWeek: "YYYY年M月DD日",
                          lastWeek: "YYYY年M月DD日",
                        })
                      : `${moment(item.startedAt).format(
                          isAndroid ? "M月DD日" : "YYYY年M月DD日"
                        )}`}
                  </div>
                </div>

                <div
                  className="row"
                  style={{
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    className="uv"
                    style={{
                      display:
                        timeCheck(item.startedAt, item.endedAt) === "notStart"
                          ? "none"
                          : "inline-block",
                    }}
                  >
                    {getPv(item.pv || 0)}人在看
                  </span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </li>
    ));
  };

  return (
    <div className="search_page">
      <div className="search">
        <img
          src={arrowIcon}
          alt=""
          onClick={() => {
            if (listPageType) {
              setListPageType(null);
            } else if (searchPage) {
              toggleSearchPage(false);
              setSearchResult(null);
            } else {
              backIndex();
            }
          }}
        />
        {listPageType ? (
          <p>
            {listPageType === "catalog"
              ? "合辑"
              : listPageType === "live"
              ? "直播"
              : "视频"}
          </p>
        ) : (
          <div>
            <img src={searchIcon} alt="" />
            <form actions="javascript:return">
              <input
                type="search"
                inputMode="search"
                value={searchVal}
                placeholder="请输入"
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    onSearch();
                  }
                }}
              />
            </form>
          </div>
        )}
        <button onClick={backIndex}>取消</button>
      </div>
      {showKeyWords && keyWords.length > 0 && !searchPage && (
        <div className="keywords">
          <p>热搜词</p>
          <ul>
            {keyWords.map((item, index) => (
              <li key={index}>
                <a
                  href={
                    item.type === "customize"
                      ? item.link
                      : item.type === "other"
                      ? item?.page?.link
                      : "javascript:;"
                  }
                  target={item.type === "default" ? "" : "_blank"}
                  onClick={() => {
                    if (item.type === "default") {
                      onSearch(item.name);
                    }
                  }}
                >
                  <img src={hotIcon} alt="" />
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      {searchPage && !listPageType && (
        <div className="search_result">
          {/* <ul>
            <li
              className={tab === "activity" ? "active" : ""}
              onClick={() => setTab("activity")}
            >
              活动
            </li>
            <li
              className={tab === "catalog" ? "active" : ""}
              onClick={() => setTab("catalog")}
            >
              合辑
            </li>
          </ul> */}
          {tab === "catalog" ? (
            <div className="result_box">
              <div>
                合辑
                <div onClick={() => setListPageType("catalog")}>
                  共 <span>{searchResult?.catalog?.total || 0}</span> 个
                  {searchResult?.catalog?.total > 2 && <img src={entryIcon} />}
                </div>
              </div>
              <ul>{renderList(searchResult?.catalog?.items?.slice(0, 2))}</ul>
            </div>
          ) : (
            <>
              <div className="result_box">
                <div>
                  直播
                  <div onClick={() => setListPageType("live")}>
                    共 <span>{searchResult?.live?.total || 0}</span> 个
                    {searchResult?.live?.total > 2 && <img src={entryIcon} />}
                  </div>
                </div>
                <ul>{renderList(searchResult?.live?.items?.slice(0, 2))}</ul>
              </div>
              <div className="result_box">
                <div>
                  视频
                  <div onClick={() => setListPageType("video")}>
                    共 <span>{searchResult?.video?.total || 0}</span> 个
                    {searchResult?.video?.total > 2 && <img src={entryIcon} />}
                  </div>
                </div>
                <ul>{renderList(searchResult?.video?.items?.slice(0, 2))}</ul>
              </div>
            </>
          )}
        </div>
      )}
      {listPageType && (
        <div className="result_box">
          <ul>{renderList(searchResult?.[listPageType]?.items)}</ul>
        </div>
      )}
    </div>
  );
};

index.propTypes = {};

export default index;
